import React, { useContext, useMemo } from 'react';
import { useState } from 'react';
import { t } from '../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { ProductMasterDataContext } from '../../context/ProductMasterDataContext';
import { ProductMasterData } from '../../types/productMasterData';
import ImportProductMasterDataModal from './ProductMasterData/Modals/ImportProductMasterDataModal';
import DeleteProductMasterDataModal from './ProductMasterData/Modals/DeleteProductMasterDataModal';
import { testIds } from '../../util/identifiers/identifiers.util';
import { CompanyContext } from '../../context/CompanyContext';
import { StockLocationContext } from '../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../types/stockLocationRoleAssignment';
import { CompanyRoleAssignmentContext } from '../../context/CompanyRoleAssignmentContext';
import { ExportProductMasterDataModal } from './ProductMasterData/Modals/ExportProductMasterDataModal';
import { ButtonProps } from '../../VentoryUI/components/common/Button/Button';
import SearchBarWithFilter from '../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { FilterEntity } from '../../VentoryUI/components/filters/filter.util';
import { DeleteButtonTemplate } from '../../VentoryUI/components/common/Button/Templates/DeleteButton';
import { ExportButtonTemplate } from '../../VentoryUI/components/common/Button/Templates/ExportButton';
import { MenuItemProps } from '../../VentoryUI/components/common/Menu/MenuItem';
import { NewMenuItemTemplate } from '../../VentoryUI/components/common/Menu/Templates/NewMenuItem';
import { ImportMenuItemTemplate } from '../../VentoryUI/components/common/Menu/Templates/ImportMenuItem';
import { SettingsMenuItemTemplate } from '../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import { ProductMasterDataFilter } from '../../VentoryUI/components/filters/Filter/ProductMasterDataFilter';
import Pane from '../../VentoryUI/components/common/Pane/Pane';
import { FlexPane } from '../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../VentoryUI/components/common/Table/Table';

export default function ReferenceDataScreen() {
  const navigate = useNavigate();

  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { currentCompany } = useContext(CompanyContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { filteredStockLocations } = useContext(StockLocationContext);

  const [items, setItems] = useState([...productMasterData.values()]);

  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<Set<string>>(new Set());

  const headers = [
    {
      key: 'productName',
      name: t().productName.singular.label,
      text: (item: ProductMasterData) => item.productName,
    },
    {
      key: 'productNumber',
      name: t().productNumber.singular.label,
      text: (item: ProductMasterData) => item.productNumber,
    },
    {
      key: 'ean',
      name: t().globalTradeItemNumber.singular.label,
      text: (item: ProductMasterData) => item.globalTradeItemNumber || '',
    },
  ];

  const buttons: ButtonProps[] = useMemo(() => {
    if (!selected.size) return [];

    return [
      { ...DeleteButtonTemplate(() => setOpenDeleteModal(true)) },
      { ...ExportButtonTemplate(() => setOpenExportModal(true)) },
    ];
  }, [selected]);

  const menuItems: MenuItemProps[] = useMemo(() => {
    if (
      ![...filteredStockLocations.values()].filter(sl =>
        hasStockLocationRole(currentCompany.id, sl.id, StockLocationRole.STOCK_LOCATION_MANAGER),
      ).length
    )
      return [];

    return [
      NewMenuItemTemplate(() => navigate('pmd/create')),
      ImportMenuItemTemplate(() => setOpenImportModal(true)),
      SettingsMenuItemTemplate(() => navigate('settings/import_configurations')),
    ];
  }, [filteredStockLocations]);

  const allItems = useMemo(() => {
    return [...productMasterData.values()];
  }, [productMasterData]);

  return (
    <>
      <ImportProductMasterDataModal open={openImportModal} setOpen={setOpenImportModal} />
      <DeleteProductMasterDataModal open={openDeleteModal} setOpen={setOpenDeleteModal} idsToDelete={selected} />
      <ExportProductMasterDataModal open={openExportModal} setOpen={setOpenExportModal} ids={selected} />

      <Pane testId={testIds.referenceDataScreen}>
        <FlexPane
          header={
            <SearchBarWithFilter
              loading={productMasterDataLoading}
              placeholder={t().filterProductReferenceData.singular.label}
              buttons={buttons}
              menuItems={menuItems}
              items={allItems}
              setItems={setItems}
              onFilter={(item, filter) => ProductMasterDataFilter.search(item, filter)}
              entity={FilterEntity.PRODUCT_MASTER_DATA}
            />
          }
          content={
            <Table
              title={t().referenceData.singular.label}
              loading={productMasterDataLoading}
              items={items}
              headers={headers}
              totalItemCount={allItems.length}
              onClick={item => navigate(`/reference_data/pmd/${item.id}/update/info`)}
              onSelected={items => setSelected(new Set(items))}
            />
          }
        />
      </Pane>
    </>
  );
}
