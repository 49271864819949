import { Company } from '../types/company';
import { ColorStyleType } from '../VentoryUI/util/color.util';

export const getBackgroundColor = (company: Company, type: ColorStyleType) => {
  return company.settings.primaryColor;
};

export const getTextColor = (company: Company, type: ColorStyleType) => {
  return company.settings.primaryColor;
};

export const getBorderColor = (company: Company, type: ColorStyleType) => {
  return company.settings.primaryColor;
};

export const getLightness = (x: number) => {
  return 0.0012 * (x * x) - 0.1495 * x + 5.1;
};
