import { t } from '../../../types/translation/Translator';
import { Task, taskStatusToString } from '../../../types/task';
import { useContext } from 'react';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import { BinContext } from '../../../context/BinContext';
import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../Common/TextInput';
import { UserContext } from '../../../context/UserContext';
import { testIds } from '../../../util/identifiers/identifiers.util';
import Table from '../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';
import { EntityType } from '../../../types/comment';

interface TaskInfoPaneInputProps {
  task?: Task;
  footer: (task: Task) => JSX.Element;
}

export default function TaskInfoPane({ task, footer }: TaskInfoPaneInputProps) {
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { bins } = useContext(BinContext);
  const { companyUsers } = useContext(UserContext);

  if (!task) return null; // TODO: Entity not found

  const binHeaders = [
    {
      key: 'bins',
      name: 'Bins',
      text: (item: string) => bins.get(item)?.name || 'Unknown Bin',
    },
  ];

  const productHeaders = [
    {
      key: 'products',
      name: 'Products',
      text: (item: string) => productMasterData.get(item)?.productName || 'Unknown Product Reference',
    },
  ];

  const userHeaders = [
    {
      key: 'users',
      name: 'Users',
      text: (item: string) => companyUsers.get(item)?.email || 'Unknown User',
    },
  ];

  console.log(task);

  return (
    <FlexPane
      testId={testIds.taskInfoPane}
      content={
        <FlexPane
          header={
            <Paper>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={4}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        onChange={() => {}}
                        disabled
                        label={t().createdAt.singular.label}
                        value={new Date(task.createdAt).toLocaleDateString()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        onChange={() => {}}
                        disabled
                        label={t().createdBy.singular.label}
                        value={companyUsers.get(task.createdBy)?.email || 'Unknown user'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        onChange={() => {}}
                        disabled
                        label={t().completedOn.singular.label}
                        placeholder={''}
                        value={task.completedOn ? new Date(task.completedOn).toLocaleDateString() : ''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        onChange={() => {}}
                        disabled
                        label={t().status.singular.label}
                        placeholder={''}
                        value={taskStatusToString(task.status, task.overdue)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}></Grid>
                {!task.dueDate ? (
                  <Grid item xs={4}>
                    <Grid container rowSpacing={1}>
                      <Grid item xs={12}>
                        <TextInput
                          onChange={() => {}}
                          disabled
                          label={t().due.singular.label}
                          placeholder={''}
                          value={task.dueDate ? new Date(task.dueDate).toLocaleDateString() : ''}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Paper>
          }
          content={
            <Grid container className='h-full' columnSpacing={1}>
              <Grid item xs={6} className='h-full'>
                {task.identifiers.filter(i => i.type === EntityType.bin).length ? (
                  <Table
                    items={task.identifiers
                      .filter(i => i.type === EntityType.bin)
                      .map(i => i.ids)
                      .flat()}
                    headers={binHeaders}
                    testId={testIds.bins}
                    title={t().bin.plural.label}
                  />
                ) : (
                  <Table
                    items={task.identifiers
                      .filter(i => i.type === EntityType.pmd)
                      .map(i => i.ids)
                      .flat()}
                    headers={productHeaders}
                    testId={testIds.products}
                    title={t().product.plural.label}
                  />
                )}
              </Grid>
              <Grid item xs={6} className='h-full'>
                <Table items={task.assignedTo} headers={userHeaders} title={t().user.plural.label} />
              </Grid>
            </Grid>
          }
        />
      }
      footer={footer(task)}
    />
  );
}
