import { TranslationKey } from './TranslationKey';

export interface TranslationRecord {
  singular: {
    upper?: string;
    lower: string;
  };
  plural?: {
    upper?: string;
    lower: string;
  };
}

export type Translations = Record<TranslationKey, TranslationPluralType>;
export function translationsFromJson(json: any): Translations {
  const t: Record<TranslationKey, TranslationPluralType> = {} as Record<TranslationKey, TranslationPluralType>;
  for (const key of Object.keys(json)) {
    const tkey = key as TranslationKey;
    const singular = json[tkey]['singular'];
    if (!singular) {
      throw Error('Singular should be required');
    }
    const sv = new TranslationCasingType(singular.lower, singular['_upper']);

    const plural = json[tkey]['plural'];
    const pv = plural ? new TranslationCasingType(plural.lower, plural['_upper']) : undefined;

    t[tkey] = new TranslationPluralType(sv, pv);
  }

  return t;
}

export class TranslationPluralType {
  singular: TranslationCasingType;
  private _plural?: TranslationCasingType;

  toRecord(): TranslationRecord {
    if (this.singular.lower === 'undefined translation') {
      return {
        singular: {
          lower: this.singular.lower,
        },
      };
    }

    const record: TranslationRecord = {
      singular: {
        lower: this.singular.lower,
      },
    };

    if (this.singular.rawUpper) record.singular.upper = this.singular.rawUpper;
    if (this._plural) {
      record.plural = { lower: this._plural.lower };
      if (this._plural.rawUpper) record.plural.upper = this._plural.rawUpper;
    }

    return record;
  }

  get plural() {
    if (this._plural) return this._plural;
    return this.singular;
  }

  constructor(singular: TranslationCasingType, plural?: TranslationCasingType) {
    this.singular = singular;
    this._plural = plural;
  }
}

class TranslationCasingType {
  lower: string;
  private _upper?: string;

  set upper(upper: string) {
    this._upper = upper;
  }

  get rawUpper() {
    return this._upper;
  }

  get upper() {
    if (this._upper) return this._upper;
    if (!this.lower) return this.lower;
    return this.lower.slice(0, 1).toUpperCase() + this.lower.slice(1);
  }

  get label() {
    return `${this.lower.charAt(0).toUpperCase()}${this.lower.substring(1)}`;
    // .split(' ')
    // .map((word, idx) => {
    //   if (!word || idx > 1) return word;
    //   return word.slice(0, 1).toUpperCase() + word.slice(1);
    // })
    // .join(' ');
  }

  constructor(lower: string, upper?: string) {
    this.lower = lower;
    this._upper = upper;
  }
}

export const undefinedTranslationRecord = new TranslationPluralType(
  new TranslationCasingType('undefined translation', undefined),
  new TranslationCasingType('undefined translation', undefined),
);

export namespace TranslationUtils {
  function extractVariables(str: string) {
    return Array.from(str.matchAll(/\$\{([^\}]+)\}/g), match => match[1]);
  }

  export function checkVariables(english: string, string: string) {
    const required = extractVariables(english);
    const found = new Set(extractVariables(string));
    return required.every(v => found.has(v));
  }

  export const allKeys = [...Object.keys(TranslationKey)] as TranslationKey[];
}
