import React, { useContext, useState } from 'react';
import { t } from '../../../../../../types/translation/Translator';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import { CompanyRelationService, IntegrationSettings } from '../../../../../../types/integrationSettings';
import CreateIntegrationSettingsTypePane from '../Panes/CreateIntegrationSettingsTypePane';
import { useMutation } from '@apollo/client';
import {
  CreateBusinessCentralIntegrationResponse,
  CreateBusinessCentralIntegrationVariables,
  CreateSAPIntegrationResponse,
  CreateSAPIntegrationVariables,
  IntegrationSettingsMutations,
} from '../../../../../../graphql/integrationSettings.graphql';
import { IntegrationContext } from '../../../../../../context/IntegrationContext';
import CreateIntegrationSettingsInfoPane from '../Panes/CreateIntegrationSettingsInfoPane';
import CreateIntegrationSettingsCompanyPane from '../Panes/CreateIntegrationSettingsCompanyPane';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';

interface CreateIntegrationModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

enum CreateIntegrationState {
  type = 'type',
  company = 'company',
  info = 'info',
}

export default function CreateIntegrationModal({ open, setOpen }: CreateIntegrationModalInputProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { integrationSettings, setIntegrationSettings } = useContext(IntegrationContext);

  const [currentPane, setCurrentPane] = useState<CreateIntegrationState>(CreateIntegrationState.type);
  const [input, setInput] = useState<IntegrationSettings>(new IntegrationSettings({ companyId: currentCompany.id }));
  const [error, setError] = useState<string>('');

  const [createSAP, { loading: SAPLoading }] = useMutation<CreateSAPIntegrationResponse, CreateSAPIntegrationVariables>(
    IntegrationSettingsMutations.createSAP,
    {
      onCompleted: res => {
        integrationSettings.set(res.createSAPIntegration.id, res.createSAPIntegration);
        setIntegrationSettings(new Map(integrationSettings));
        handleClose();
      },
      onError: err => setError(err.message),
    },
  );

  const [createBusinessCentral, { loading: businessCentralLoading }] = useMutation<
    CreateBusinessCentralIntegrationResponse,
    CreateBusinessCentralIntegrationVariables
  >(IntegrationSettingsMutations.createBusinessCentral, {
    onCompleted: res => {
      integrationSettings.set(res.createBusinessCentralIntegration.id, res.createBusinessCentralIntegration);
      setIntegrationSettings(new Map(integrationSettings));
      handleClose();
    },
    onError: err => setError(err.message),
  });

  const handleClose = () => {
    setError('');
    setCurrentPane(CreateIntegrationState.type);
    setInput(new IntegrationSettings({ companyId: currentCompany.id }));
    setOpen(false);
  };

  const handleNext = () => {
    setError('');

    switch (currentPane) {
      case CreateIntegrationState.type:
        return setCurrentPane(CreateIntegrationState.info);
      case CreateIntegrationState.info:
        input.type === CompanyRelationService.businessCentral ? setCurrentPane(CreateIntegrationState.company) : null;
      case CreateIntegrationState.company:
        handleFinish();
    }
  };

  const handleBack = () => {
    setError('');

    switch (currentPane) {
      case CreateIntegrationState.type:
        return;
      case CreateIntegrationState.info:
        return;
      case CreateIntegrationState.company:
        return setCurrentPane(CreateIntegrationState.info);
    }
  };

  const handleFinish = async () => {
    if (input.type === CompanyRelationService.sap) return handleSAPFinish(input);
    else if (input.type === CompanyRelationService.businessCentral) return handleBusinessCentralFinish(input);
  };

  const handleSAPFinish = async (settingsInput: IntegrationSettings) => {
    if (settingsInput.type !== CompanyRelationService.sap) return;
    if (!settingsInput.externalId) return;
    if (!settingsInput.url) return;
    if (!settingsInput.apiKey) return;
    if (!settingsInput.plantId) return;

    try {
      await createSAP({
        variables: {
          companyId: currentCompany.id,
          externalId: settingsInput.externalId,
          name: settingsInput.name,
          url: settingsInput.url,
          apiKey: settingsInput.apiKey,
          plantId: settingsInput.plantId,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleBusinessCentralFinish = async (settingsInput: IntegrationSettings) => {
    if (settingsInput.type !== CompanyRelationService.businessCentral) return;
    if (!settingsInput.tenantId) return;
    if (!settingsInput.environment) return;
    if (!settingsInput.clientId) return;
    if (!settingsInput.clientSecret) return;
    if (!settingsInput.externalId) return;

    try {
      await createBusinessCentral({
        variables: {
          companyId: currentCompany.id,
          name: settingsInput.name,
          tenantId: settingsInput.tenantId,
          environment: settingsInput.environment,
          clientId: settingsInput.clientId,
          clientSecret: settingsInput.clientSecret,
          externalId: settingsInput.externalId,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const content = () => {
    switch (currentPane) {
      case CreateIntegrationState.type:
        return <CreateIntegrationSettingsTypePane settings={input} setSettings={setInput} handleNext={handleNext} />;
      case CreateIntegrationState.info:
        return (
          <CreateIntegrationSettingsInfoPane
            settings={input}
            setSettings={setInput}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case CreateIntegrationState.company:
        return (
          <CreateIntegrationSettingsCompanyPane
            settings={input}
            setSettings={setInput}
            handleNext={handleNext}
            handleBack={handleBack}
            setError={setError}
            loading={businessCentralLoading}
          />
        );
    }
    return <></>;
  };

  return (
    <Modal error={error} open={open} onClose={handleClose} height='550px' title={t().createIntegration.singular.label}>
      {content()}
    </Modal>
  );
}
