import React, { useContext, useEffect, useMemo, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { ProductContext } from '../../../context/ProductContext';
import { Product } from '../../../types/product';
import ProductImportModal from './Modals/ProductImportModal';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import { StockLocationContext } from '../../../context/StockLocationContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';
import { CompanyRoleAssignmentContext } from '../../../context/CompanyRoleAssignmentContext';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { UserContext } from '../../../context/UserContext';
import { SuperUserContext } from '../../../context/SuperUserContext';
import { CompanyRole } from '../../../types/companyRoleAssignment';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { ProductExportModal } from './Modals/ProductExportModal';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { FilterEntity } from '../../../VentoryUI/components/filters/filter.util';
import { getSuffix, toQuantityString } from '../../../types/unitOfMeasure';
import { removeDiacritics, toFilterString } from '../../../util/string.util';
import ImportIcon from '../../../VentoryUI/icons/Import/ImportIcon';
import SettingsIcon from '../../../VentoryUI/icons/Settings/SettingsIcon';
import ExportIcon from '../../../VentoryUI/icons/Export/ExportIcon';
import { MenuItemProps } from '../../../VentoryUI/components/common/Menu/MenuItem';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../VentoryUI/components/common/Table/Table';

export default function ProductOverviewPane() {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { products, productsLoading } = useContext(ProductContext);
  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { filteredStockLocations, stockLocations } = useContext(StockLocationContext);
  const { companyRoles, hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { currentUser } = useContext(UserContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { superUser } = useContext(SuperUserContext);

  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const headers = [
    {
      key: 'productName',
      name: t().productName.singular.label,
      text: (id: string) => productMasterData.get(id)?.productName || '',
      columnWidth: 6,
    },
    {
      key: 'productNumber',
      name: t().productNumber.singular.label,
      text: (id: string) => productMasterData.get(id)?.productNumber || '',
      columnWidth: 6,
    },
    {
      key: 'quantity',
      name: t().quantity.singular.label,
      sortValue: (id: string) => groupedProducts.get(id) || 0,
      text: (id: string) =>
        `${toQuantityString(
          groupedProducts.get(id)?.toString() || '0',
          productMasterData.get(id)?.unitOfMeasure,
        )} ${getSuffix(productMasterData.get(id)?.unitOfMeasure)}`,
    },
  ];

  const handleFilter = (item: Product, filter: string) => {
    const productName = productMasterData.get(item.productMasterDataId)?.productName;
    const productNumber = productMasterData.get(item.productMasterDataId)?.productNumber;

    if (
      removeDiacritics(toFilterString(productName)).includes(filter) ||
      removeDiacritics(toFilterString(productNumber)).includes(filter)
    ) {
      return true;
    }
    return false;
  };

  const getGroupedProducts = () => {
    const grouped: Map<string, BigInt> = new Map();
    for (const item of items) {
      grouped.set(
        item.productMasterDataId,
        BigInt((grouped.get(item.productMasterDataId) || BigInt('0')).toString()) + BigInt(item.unitQuantity),
      );
    }
    return grouped;
  };

  const role = companyRoles.get(currentUser?.userId || '')?.find(cr => cr.companyId === currentCompany.id);
  if (!role && !superUser) return null;

  const possibleStockLocations = [...stockLocations.values()].filter(sl => {
    const role = stockLocationRoles
      .get(sl.id)
      ?.flat()
      .find(u => u.userId === currentUser?.userId)?.role;

    if (role !== StockLocationRole.STOCK_LOCATION_MANAGER) return false;
    return true;
  });

  const allItems = useMemo(() => {
    return [...products.values()].map(item => [...item.values()]).flat();
  }, [products]);

  const [items, setItems] = useState<Product[]>(allItems);
  const [groupedProducts, setGroupedProducts] = useState<Map<string, BigInt>>(getGroupedProducts());

  useEffect(() => {
    setGroupedProducts(getGroupedProducts());
  }, [items]);

  const menuItems: MenuItemProps[] = useMemo(() => {
    const shown: MenuItemProps[] = [
      {
        icon: <ExportIcon />,
        text: t().exportProducts.singular.label,
        onClick: () => setExportModalOpen(true),
      },
    ];

    const isAdmin = hasCompanyRole(currentCompany.id, CompanyRole.administrator);

    if (possibleStockLocations.length || isAdmin) {
      shown.push({
        text: t().import.singular.label,
        testId: testIds.import,
        icon: <ImportIcon />,
        onClick: () => setImportModalOpen(true),
      });
    }

    if (isAdmin) {
      shown.push({
        text: t().settings.singular.label,
        onClick: () => navigate('settings/import_configurations'),
        testId: testIds.settings,
        icon: <SettingsIcon />,
      });
    }

    return shown;
  }, [possibleStockLocations]);

  return (
    <>
      <FlexPane
        testId={testIds.productOverviewPane}
        header={
          <SearchBarWithFilter
            loading={productsLoading || productMasterDataLoading}
            items={allItems}
            setItems={setItems}
            placeholder={t().filterProducts.singular.label}
            entity={FilterEntity.PRODUCT}
            onFilter={handleFilter}
            testId={testIds.products}
            menuItems={menuItems}
          />
        }
        content={
          <Table
            loading={productsLoading || productMasterDataLoading}
            title={t().product.plural.label}
            headers={headers}
            totalItemCount={allItems.length}
            items={[
              ...new Set(
                items
                  .map(item => item.productMasterDataId)
                  .filter(item => productMasterData.get(item))
                  .values(),
              ),
            ]}
            onClick={item => navigate(`/stock/products/${item}/info`)}
          />
        }
      />

      <ProductImportModal open={importModalOpen} setOpen={setImportModalOpen} />
      <ProductExportModal
        productIds={new Set(items.map(item => item.id))}
        open={exportModalOpen}
        setOpen={setExportModalOpen}
      />
    </>
  );
}
