import Color from 'color';
import { Company } from '../../types/company';
import { StyleType } from '../components/common/Style';

export class VentoryColor {
  static headerVentoryColor = '#181E29';
  static primaryVentoryColor = '#00F28D';
  static white = '#FFFFFF';
  static black = '#000000';
  static dark = '#12171F';

  static deleteRed = '#F04438';
  static deleteRedBackground = '#FEF3F2';

  static grey50 = '#FFFAEB';
  static grey200 = '#EAECF0';
  static grey300 = '#D0D5DD';
  static grey400 = '#98A2B3';
  static grey500 = '#667085';
  static grey700 = '#344054';
  static grey900 = '#101828';

  static green50 = '#ECFDF3';
  static green700 = '#027A48';

  static orange50 = '#FFFAEB';
  static orange700 = '#B54708';

  static red50 = '#FEF3F2';
  static red700 = '#B42318';

  // Temporarily taking from tailwind as there are no designed ones yet
  static green500 = '#22c55e';

  static blue50 = '#eff6ff';
  static blue700 = '#1d4ed8';

  static yellow50 = '#fefce8';
  static yellow700 = '#a16207';
}

// To be deprecated
export enum ColorStyleType {
  ventoryDefault = 'ventoryDefault',
  ventorySecondary = 'ventorySecondary',
  primary = 'primary',
  secondary = 'secondary',
}

export namespace Colors {
  export const getBackgroundColor = (company: Company, type: StyleType) => {
    switch (type) {
      case 'primary':
        return VentoryColor.white;
      case 'secondary':
        return getAccentColor(company);
    }
  };

  export const getTextColor = (company: Company, type: StyleType) => {
    return new Color(getBackgroundColor(company, type)).isDark() ? 'white' : 'black';
  };

  export const LIGHT_BACKGROUND_LIGHTNESS = 95;
  export function lightBackground(color: string) {
    return new Color(color).lightness(LIGHT_BACKGROUND_LIGHTNESS).string();
  }
  export function companyLightBackground(company: Company) {
    return lightBackground(getAccentColor(company));
  }

  export const DARK_TEXT_LIGHTNESS = 20;
  export function darkText(color: string) {
    return new Color(color).lightness(DARK_TEXT_LIGHTNESS).string();
  }
  export function companyDarkText(company: Company) {
    return darkText(getAccentColor(company));
  }

  export function getAccentColor(company: Company) {
    return company.settings.accentColor || company.settings.primaryColor;
  }

  export function getHeaderColor(company: Company) {
    return company.settings.headerColor;
  }
}
