import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { TaskType, validateTaskType } from './task';
import { VentorySubscriptionService, VentorySubscriptionState, VentorySubscriptionType } from './billing';

enum CompanyValidationError {
  name = 'Please enter a name',
}

interface CompanyStatus {
  service: VentorySubscriptionService;
  type: VentorySubscriptionType;
  state: VentorySubscriptionState;
}

export class Company extends CompanyEntity {
  name!: string;
  settings!: CompanySettings;
  status?: CompanyStatus;

  get validTaskTypes() {
    return (Object.keys(TaskType) as TaskType[]).filter(type => validateTaskType(this, type));
  }

  constructor(obj: any) {
    super(obj.id);
    Object.assign(this, cloneDeep(obj));
    this.companyId = obj.id;
    if (!obj.settings) {
      (this.settings as any) = {
        primaryColor: '#00F28D',
        accentColor: '#00F28D',
        headerColor: '#181E29',
      };
    } else {
      if ((obj.settings as CompanySettings).featureToggles.productTransactions.mobile === null) {
        this.settings.featureToggles.productTransactions.mobile = true;
      }
      if ((obj.settings as CompanySettings).featureToggles.productTransactions.web === null) {
        this.settings.featureToggles.productTransactions.web = true;
      }
      if ((obj.settings as CompanySettings).featureToggles.productTransactions.quickActions === null) {
        this.settings.featureToggles.productTransactions.quickActions = true;
      }
      if ((obj.settings as CompanySettings).featureToggles.orders.customer === null) {
        this.settings.featureToggles.orders.customer = true;
      }
      if ((obj.settings as CompanySettings).featureToggles.orders.supplier === null) {
        this.settings.featureToggles.orders.supplier = true;
      }
      if ((obj.settings as CompanySettings).featureToggles.orders.editProductsOnInbound === null) {
        this.settings.featureToggles.orders.editProductsOnInbound = true;
      }
      if ((obj.settings as CompanySettings).featureToggles.orders.editProductsOnOutbound === null) {
        this.settings.featureToggles.orders.editProductsOnOutbound = true;
      }
      if ((obj.settings as CompanySettings).featureToggles.orders.sendMailOnAssigned === null) {
        this.settings.featureToggles.orders.editProductsOnOutbound = false;
      }
      if ((obj.settings as CompanySettings).featureToggles.orders.ignoreAvailableQuantity === null) {
        this.settings.featureToggles.orders.ignoreAvailableQuantity = false;
      }
      if ((obj.settings as CompanySettings).featureToggles.tasks.blindCount === null) {
        this.settings.featureToggles.tasks.blindCount = true;
      }
      if ((obj.settings as CompanySettings).featureToggles.tasks.cycleCount === null) {
        this.settings.featureToggles.tasks.cycleCount = true;
      }
      if ((obj.settings as CompanySettings).featureToggles.tasks.taskOrders === null) {
        this.settings.featureToggles.tasks.taskOrders = {
          taskOrders: false,
          inbound: false,
          move: false,
          outbound: false,
        };
      }
    }

    this.settings.headerColor = this.settings.headerColor || this.settings.primaryColor;
    this.settings.accentColor = this.settings.accentColor || this.settings.primaryColor;
  }

  forUpdate(): UpdateCompanyInput {
    return UpdateCompanyInput.from(this, UpdateCompanyInput);
  }

  forDelete(): DeleteCompanyInput {
    return DeleteCompanyInput.from(this, DeleteCompanyInput);
  }

  validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this), field => {
      if (field === 'name' && !this.name) return CompanyValidationError.name;
      return null;
    });
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withSettings(settings: CompanySettings) {
    this.settings = settings;
    return this;
  }

  withPrimaryColor(color: string) {
    this.settings.primaryColor = color;
    return cloneDeep(this);
  }

  withAlertsAlertFeatureToggle(value: boolean) {
    this.settings.featureToggles.alerts.alerts = value;
    return cloneDeep(this);
  }

  withOrdersOrderFeatureToggle(value: boolean) {
    this.settings.featureToggles.orders.orders = value;
    if (!value) {
      this.settings.featureToggles.orders.supplier = value;
      this.settings.featureToggles.orders.customer = value;
      this.settings.featureToggles.orders.editProductsOnInbound = value;
      this.settings.featureToggles.orders.editProductsOnOutbound = value;
      this.settings.featureToggles.orders.productSelectionFirst = value;
    }
    return cloneDeep(this);
  }

  withOrdersExternalReferenceId(value: boolean) {
    this.settings.featureToggles.orders.externalReferenceId = value;
    return cloneDeep(this);
  }

  withOrdersPurchaseOrderNumber(value: boolean) {
    this.settings.featureToggles.orders.purchaseOrderNumber = value;
    return cloneDeep(this);
  }

  withOrdersEstimatedTimeOfArrival(value: boolean) {
    this.settings.featureToggles.orders.estimatedTimeOfArrival = value;
    return cloneDeep(this);
  }

  withOrdersCustomer(value: boolean) {
    this.settings.featureToggles.orders.customer = value;
    return cloneDeep(this);
  }

  withOrdersSupplier(value: boolean) {
    this.settings.featureToggles.orders.supplier = value;
    return cloneDeep(this);
  }

  withOrderSendMailOnAssigned(value: boolean) {
    this.settings.featureToggles.orders.sendMailOnAssigned = value;
    return cloneDeep(this);
  }

  withOrdersEditProductsOnInbound(value: boolean) {
    this.settings.featureToggles.orders.editProductsOnInbound = value;
    return cloneDeep(this);
  }

  withOrdersEditProductsOnOutbound(value: boolean) {
    this.settings.featureToggles.orders.editProductsOnOutbound = value;
    return cloneDeep(this);
  }

  withOrdersProductSelectionFirst(value: boolean) {
    this.settings.featureToggles.orders.productSelectionFirst = value;
    return cloneDeep(this);
  }

  withOrdersIgnoreAvailableQuantity(value: boolean) {
    this.settings.featureToggles.orders.ignoreAvailableQuantity = value;
    return cloneDeep(this);
  }

  withProductTransactionsProductTransactionFeatureToggle(value: boolean) {
    this.settings.featureToggles.productTransactions.productTransactions = value;
    if (!value) {
      this.withOrdersOrderFeatureToggle(false);

      this.settings.featureToggles.productTransactions.web = value;
      this.settings.featureToggles.productTransactions.mobile = value;
    }
    return cloneDeep(this);
  }

  withWebProductTransactionFeatureToggle(value: boolean) {
    this.settings.featureToggles.productTransactions.web = value;
    return cloneDeep(this);
  }

  withMobileProductTransactionFeatureToggle(value: boolean) {
    this.settings.featureToggles.productTransactions.mobile = value;
    return cloneDeep(this);
  }

  withQuickActionsProductTransactionFeatureToggle(value: boolean) {
    this.settings.featureToggles.productTransactions.quickActions = value;
    return cloneDeep(this);
  }

  withProductsGS1FeatureToggle(value: boolean) {
    this.settings.featureToggles.products.gs1Barcodes = value;
    return cloneDeep(this);
  }

  withProductsProductFeatureToggle(value: boolean) {
    this.settings.featureToggles.products.products = value;
    return cloneDeep(this);
  }

  withTasksTaskFeatureToggle(value: boolean) {
    this.settings.featureToggles.tasks.tasks = value;
    if (!value) {
      this.settings.featureToggles.tasks.allowStockUpdate = value;
      this.settings.featureToggles.tasks.cycleCount = value;
      this.settings.featureToggles.tasks.blindCount = value;
      this.withTaskOrderToggle(value);
    } else if (value) {
      this.settings.featureToggles.tasks.cycleCount = true;
    }
    return cloneDeep(this);
  }

  withTasksStockUpdateToggle(value: boolean) {
    this.settings.featureToggles.tasks.allowStockUpdate = value;
    return cloneDeep(this);
  }

  withTaskOrderToggle(value: boolean) {
    this.settings.featureToggles.tasks.taskOrders.taskOrders = value;
    if (!value) {
      this.settings.featureToggles.tasks.taskOrders.inbound = value;
      this.settings.featureToggles.tasks.taskOrders.move = value;
      this.settings.featureToggles.tasks.taskOrders.outbound = value;
    }
    return cloneDeep(this);
  }

  withTaskOrderInboundToggle(value: boolean) {
    this.settings.featureToggles.tasks.taskOrders.inbound = value;
    return cloneDeep(this);
  }

  withTaskOrderMoveToggle(value: boolean) {
    this.settings.featureToggles.tasks.taskOrders.move = value;
    return cloneDeep(this);
  }

  withTaskOrderOutboundToggle(value: boolean) {
    this.settings.featureToggles.tasks.taskOrders.outbound = value;
    return cloneDeep(this);
  }

  withCompanyVentoryVisionARFeatureToggle(value: boolean) {
    this.settings.featureToggles.company.ventoryVisionAR = value;
    return cloneDeep(this);
  }

  withCompanySessionStorageFeatureToggle(value: boolean) {
    this.settings.featureToggles.company.sessionStorage = value;
    return cloneDeep(this);
  }

  withTasksCycleCountToggle(value: boolean) {
    this.settings.featureToggles.tasks.cycleCount = value;
    return cloneDeep(this);
  }

  withTasksBlindCountToggle(value: boolean) {
    this.settings.featureToggles.tasks.blindCount = value;
    return cloneDeep(this);
  }

  withAccentColor(value: string) {
    this.settings.accentColor = value;
    return cloneDeep(this);
  }

  withHeaderColor(value: string) {
    this.settings.headerColor = value;
    return cloneDeep(this);
  }
}

export class CreateCompanyInput extends forCreate(Company) {}

export class UpdateCompanyInput extends forUpdate(Company) {}

export class DeleteCompanyInput extends forDelete(Company) {}

export interface CompanySettings {
  primaryColor: string;

  headerColor: string;
  accentColor: string;

  companyImage?: string;
  companyLogo?: string;
  companyFavicon?: string;
  scanConfigurations?: ScanConfiguration[];
  featureToggles: FeatureToggles;
}

export interface ScanConfiguration {
  regex: string;
  regexOption?: string;
  barcodeFormat: BarcodeFormat;
  type: ScanConfigurationType;
}

export interface FeatureToggles {
  productTransactions: ProductTransactionFeatureToggles;
  orders: OrderFeatureToggles;
  tasks: TaskFeatureToggles;
  products: ProductFeatureToggles;
  alerts: AlertFeatureToggles;
  company: CompanyFeatureToggles;
}

export interface ProductTransactionFeatureToggles {
  productTransactions: boolean;
  web: boolean;
  mobile: boolean;
  quickActions: boolean;
}

export interface CompanyFeatureToggles {
  ventoryVisionAR: boolean;
  sessionStorage: boolean;
}

export interface OrderFeatureToggles {
  orders: boolean;
  externalReferenceId: boolean;
  purchaseOrderNumber: boolean;
  estimatedTimeOfArrival: boolean;
  customer: boolean;
  supplier: boolean;
  editProductsOnInbound: boolean;
  editProductsOnOutbound: boolean;
  productSelectionFirst: boolean;
  sendMailOnAssigned: boolean;
  ignoreAvailableQuantity: boolean;
}

export interface TaskFeatureToggles {
  tasks: boolean;
  allowStockUpdate: boolean;
  cycleCount: boolean;
  blindCount: boolean;
  taskOrders: TaskOrderFeatureToggles;
}

export interface TaskOrderFeatureToggles {
  taskOrders: boolean;
  inbound: boolean;
  move: boolean;
  outbound: boolean;
}

export interface ProductFeatureToggles {
  products: boolean;
  container: boolean;
  gs1Barcodes: boolean;
}

export interface AlertFeatureToggles {
  alerts: boolean;
}

export enum ScanConfigurationType {
  serial = 'serial',
  lpn = 'lpn',
  bin = 'bin',
  productNumber = 'productNumber',
  lot = 'lot',
  coo = 'coo',
  stockLocation = 'stockLocation',
  container = 'container',
}

export function scanConfigurationTypeToString(item: ScanConfigurationType) {
  switch (item) {
    case ScanConfigurationType.serial:
      return 'Serial Number';
    case ScanConfigurationType.lpn:
      return 'LPN';
    case ScanConfigurationType.bin:
      return 'Bin';
    case ScanConfigurationType.productNumber:
      return 'Product Number';
    case ScanConfigurationType.lot:
      return 'Lot';
    case ScanConfigurationType.coo:
      return 'Country Of Origin';
    case ScanConfigurationType.stockLocation:
      return 'Stock Location';
    case ScanConfigurationType.container:
      return 'Container';
  }
}

export enum BarcodeFormat {
  /// Barcode format representing all supported formats.
  all = 'all',
  /// Barcode format unknown to the current SDK.
  unknown = 'unknown',
  /// Barcode format constant for Code 128.
  code128 = 'code128',
  /// Barcode format constant for Code 39.
  code39 = 'code39',
  /// Barcode format constant for Code 93.
  code93 = 'code93',
  /// Barcode format constant for CodaBar.
  codabar = 'codabar',
  /// Barcode format constant for Data Matrix.
  dataMatrix = 'dataMatrix',
  /// Barcode format constant for EAN-13.
  ean13 = 'ean13',
  /// Barcode format constant for EAN-8.
  ean8 = 'ean8',
  /// Barcode format constant for ITF (Interleaved Two-of-Five).
  itf = 'itf',
  /// Barcode format constant for QR Code.
  qrCode = 'qrCode',
  /// Barcode format constant for UPC-A.
  upca = 'upca',
  /// Barcode format constant for UPC-E.
  upce = 'upce',
  /// Barcode format constant for PDF-417.
  pdf417 = 'pdf417',
  /// Barcode format constant for AZTEC.
  aztec = 'aztec',
}

export function barcodeFormatToString(item: BarcodeFormat) {
  switch (item) {
    case BarcodeFormat.all:
      return 'All';
    case BarcodeFormat.unknown:
      return 'Unknown';
    case BarcodeFormat.code128:
      return 'Code 128';
    case BarcodeFormat.code39:
      return 'Code 39';
    case BarcodeFormat.code93:
      return 'Code 93';
    case BarcodeFormat.codabar:
      return 'CodaBar';
    case BarcodeFormat.dataMatrix:
      return 'Data Matrix';
    case BarcodeFormat.ean13:
      return 'EAN-13';
    case BarcodeFormat.ean8:
      return 'EAN-8';
    case BarcodeFormat.itf:
      return 'ITF';
    case BarcodeFormat.qrCode:
      return 'QR Code';
    case BarcodeFormat.upca:
      return 'UPC-A';
    case BarcodeFormat.upce:
      return 'UPC-E';
    case BarcodeFormat.pdf417:
      return 'PDF-417';
    case BarcodeFormat.aztec:
      return 'AZTEC';
  }
}
