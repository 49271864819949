import React, { useContext, useEffect, useState } from 'react';
import SearchBar from '../SearchBar/SearchBar';
import { Grid } from '@mui/material';
import {
  EntityFilter,
  FilterEntity,
  getFilterCount,
  getSaveFilter,
  replacer,
  reviver,
  storeSavedFilter,
} from '../../filters/filter.util';
import { CompanyContext } from '../../../../context/CompanyContext';
import { OrderFilter } from '../../filters/Filter/OrderFilter';
import { Order } from '../../../../types/order';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { Task } from '../../../../types/task';
import { TaskFilter } from '../../filters/Filter/TaskFilter';
import { ProductFilter } from '../../filters/Filter/ProductFilter';
import { Product } from '../../../../types/product';
import { BinFilter } from '../../filters/Filter/BinFilter';
import { Bin } from '../../../../types/bin';
import { ProductTransactionFilter } from '../../filters/Filter/ProductTransactionFilter';
import { ProductTransaction } from '../../../../types/productTransaction';
import { Button, ButtonProps } from '../Button/Button';
import FilterModal from './FilterModal';
import { MenuItemProps } from '../Menu/MenuItem';
import Menu from '../Menu/Menu';
import FilterIcon from '../../../icons/Filter/FilterIcon';
import { ProductMasterDataFilter } from '../../filters/Filter/ProductMasterDataFilter';
import { ProductMasterData } from '../../../../types/productMasterData';
import { DynamicEntityFilterType } from '../../filters/DynamicEntityFilter/DynamicEntityFilterType';
import { ShippingLocationFilter } from '../../filters/Filter/ShippingLocationFilter';
import { Contact, ShippingLocation } from '../../../../types/contact';
import { toFilterString } from '../../../../util/string.util';
import { BinStatus } from '../../../../types/binStatus';
import { BinStatusFilter } from '../../filters/Filter/BinStatusFilter';
import { ScanRuleFilter } from '../../filters/Filter/ScanRuleFilter';
import { ScanRule } from '../../../../types/scanRule';
import { CustomFieldFilter } from '../../filters/Filter/CustomFieldFilter';
import { CustomField } from '../../../../types/customField';
import { ScanConfigurationFilter } from '../../filters/Filter/ScanConfigurationFilter';
import { ScanConfiguration } from '../../../../types/company';
import { ReorderRuleFilter } from '../../filters/Filter/ReorderRuleFilter';
import { ReorderUserRuleFilter } from '../../filters/Filter/ReorderUserRuleFilter';
import { ReorderRuleGroup } from '../../../../components/Common/ReorderRules/ReorderRulesOverviewPane';
import { ReorderUserRuleGroup } from '../../../../components/Operation/Order/Settings/Panes/OrderReorderUsersPane';
import { StockLocationFilter } from '../../filters/Filter/StockLocationFilter';
import { StockLocation } from '../../../../types/stockLocation';
import { TriggerFilter } from '../../filters/Filter/TriggerFilter';
import { Trigger } from '../../../../types/trigger';
import { ContactFilter } from '../../filters/Filter/ContactFilter';
import { CompanyRoleAssignment } from '../../../../types/companyRoleAssignment';
import { CompanyRoleAssignmentFilter } from '../../filters/Filter/UserFilter';
import { AlertFilter } from '../../filters/Filter/AlertFilter';
import { Alert } from '../../../../types/alert';
import { TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import { FileFilter } from '../../filters/Filter/FileFilter';
import { FileEntity } from '../../../../types/file';
import { ReorderRuleProductFilter } from '../../filters/Filter/ReorderRuleProductFilter';
import { ReorderRuleProduct } from '../../../../components/Common/ReorderRules/ReorderRuleScreen';
import { TranslationKey } from '../../../../types/translation/TranslationKey';
import { TranslationFilter } from '../../filters/Filter/TranslationFilter';
import { t } from '../../../../types/translation/Translator';

interface SearchBarWithFilterProps<T> {
  loading?: boolean;
  items: T[];
  setItems: (items: T[]) => void;
  menuItems?: MenuItemProps[];
  buttons?: ButtonProps[];
  additional?: React.ReactNode[];
  placeholder?: string;
  entity: FilterEntity;
  onFilter: (item: T, text: string) => boolean;
  testId?: TestIdIdentifier;
  useFilterFn?: (filter: string) => boolean;
}

export default function SearchBarWithFilter<T>({
  loading = false,
  placeholder,
  entity,
  items,
  setItems,
  onFilter,
  testId,
  menuItems,
  buttons,
  additional,
  useFilterFn = () => true,
}: SearchBarWithFilterProps<T>) {
  const { currentCompany } = useContext(CompanyContext);
  const { filteredStockLocations } = useContext(StockLocationContext);

  const [itemCount, setItemCount] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);

  const savedFilter = getSaveFilter(entity);
  const [filter, setFilter] = useState<EntityFilter>(
    savedFilter ? (JSON.parse(savedFilter, reviver) as EntityFilter) : new EntityFilter(),
  );
  const [textFilter, setTextFilter] = useState<string>('');

  const handleChange = (item: string) => {
    setTextFilter(toFilterString(item));
  };

  const handleFilter = () => {
    switch (entity) {
      case FilterEntity.ORDER:
        return setItems([...OrderFilter.filter(filter, textFilter, items as Order[], onFilter)] as T[]);
      case FilterEntity.TASK:
        return setItems([
          ...TaskFilter.filter(filter, textFilter, items as Task[], onFilter, filteredStockLocations),
        ] as T[]);
      case FilterEntity.BIN:
        return setItems([...BinFilter.filter(filter, textFilter, items as Bin[], onFilter)] as T[]);
      case FilterEntity.PRODUCT_TRANSACTION:
        return setItems([
          ...ProductTransactionFilter.filter(filter, textFilter, items as ProductTransaction[], onFilter),
        ] as T[]);
      case FilterEntity.PRODUCT:
        return setItems([...ProductFilter.filter(filter, textFilter, items as Product[], onFilter)] as T[]);
      case FilterEntity.PRODUCT_MASTER_DATA:
        return setItems([
          ...ProductMasterDataFilter.filter(filter, textFilter, items as ProductMasterData[], onFilter),
        ] as T[]);
      case FilterEntity.SHIPPING_LOCATION:
        return setItems([
          ...ShippingLocationFilter.filter(filter, textFilter, items as ShippingLocation[], onFilter),
        ] as T[]);
      case FilterEntity.BIN_STATUS:
        return setItems([...BinStatusFilter.filter(filter, textFilter, items as BinStatus[], onFilter)] as T[]);
      case FilterEntity.SCAN_RULE:
        return setItems([...ScanRuleFilter.filter(filter, textFilter, items as ScanRule[], onFilter)] as T[]);
      case FilterEntity.CUSTOM_FIELD:
        return setItems([...CustomFieldFilter.filter(filter, textFilter, items as CustomField[], onFilter)] as T[]);
      case FilterEntity.SCAN_CONFIGURATION:
        return setItems([
          ...ScanConfigurationFilter.filter(filter, textFilter, items as ScanConfiguration[], onFilter),
        ] as T[]);
      case FilterEntity.REORDER_RULE:
        return setItems([
          ...ReorderRuleFilter.filter(filter, textFilter, items as ReorderRuleGroup[], onFilter),
        ] as T[]);
      case FilterEntity.REORDER_USER_RULE:
        return setItems([
          ...ReorderUserRuleFilter.filter(filter, textFilter, items as ReorderUserRuleGroup[], onFilter),
        ] as T[]);
      case FilterEntity.REORDER_RULE_PRODUCT:
        return setItems([
          ...ReorderRuleProductFilter.filter(filter, textFilter, items as ReorderRuleProduct[], onFilter),
        ] as T[]);
      case FilterEntity.STOCK_LOCATION:
        return setItems([...StockLocationFilter.filter(filter, textFilter, items as StockLocation[], onFilter)] as T[]);
      case FilterEntity.TRIGGER:
        return setItems([...TriggerFilter.filter(filter, textFilter, items as Trigger[], onFilter)] as T[]);
      case FilterEntity.CONTACT:
        return setItems([...ContactFilter.filter(filter, textFilter, items as Contact[], onFilter)] as T[]);
      case FilterEntity.USER:
        return setItems([
          ...CompanyRoleAssignmentFilter.filter(filter, textFilter, items as CompanyRoleAssignment[], onFilter),
        ] as T[]);
      case FilterEntity.ALERT:
        return setItems([...AlertFilter.filter(filter, textFilter, items as Alert[], onFilter)] as T[]);
      case FilterEntity.FILE:
        return setItems([...FileFilter.filter(filter, textFilter, items as FileEntity[], onFilter)] as T[]);
      case FilterEntity.TRANSLATION:
        return setItems([...TranslationFilter.filter(textFilter, items as TranslationKey[], onFilter)] as T[]);
    }
  };

  useEffect(() => {
    handleFilter();
    storeSavedFilter(entity, JSON.stringify(filter, replacer));
  }, [loading, filter, textFilter]);

  useEffect(() => {
    handleFilter();
    storeSavedFilter(entity, JSON.stringify(filter, replacer));
    setItemCount(items.length);
  }, [items]);

  return (
    <>
      <FilterModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        filterEntity={entity}
        filter={filter}
        setFilter={setFilter}
        useFilterFn={useFilterFn}
      />

      <Grid container>
        <Grid item flexGrow={1}>
          <Grid container>
            <Grid item pr={1}>
              <SearchBar onChange={handleChange} placeholder={placeholder} testId={testId} width={'w-[320px]'} />
            </Grid>
            {[...DynamicEntityFilterType.forEntity(entity)].filter(useFilterFn).length ? (
              <Grid item>
                <Button
                  startIcon={<FilterIcon />}
                  text={t().filter.singular.label}
                  badge={getFilterCount(filter)}
                  onClick={() => setModalOpen(true)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Grid item>
          <Grid container columnSpacing={2}>
            <Grid item>
              {buttons?.length ? (
                <Grid container columnSpacing={1}>
                  {buttons.map((b, index) => (
                    <Button {...b} key={index} />
                  ))}
                </Grid>
              ) : null}
            </Grid>
            <Grid item>
              {additional?.length ? (
                <Grid container columnSpacing={1}>
                  {additional.map(e => e)}
                </Grid>
              ) : null}
            </Grid>
            <Menu items={menuItems || []} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
