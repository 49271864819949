import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { CreateTaskInput, DeleteTaskInput, Task, UpdateTaskInput } from '../types/task';

const task = gql`
  fragment Task on Task {
    id
    version
    companyId
    createdAt
    createdBy
    stockLocationId
    assignedTo
    assignedForRecount
    status
    type
    identifiers {
      type
      ids
    }
    parentId
    parentType
    externalReferenceId
    dueDate
    completedOn
    overdue
    additionalEmails
    number
    validation {
      quantity
      validationFields
    }
  }
`;

const get = gql`
  query Tasks($companyId: String!, $page: Float) {
    tasks(companyId: $companyId, page: $page) {
      data {
        ...Task
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${task}
`;

export interface GetTasksVariables {
  companyId: string;
  page?: number;
}

export interface GetTasksResponse {
  tasks: PaginatedTasks;
}

class PaginatedTasks extends forPaginated<Task>() {}

const create = gql`
  mutation CreateTask($tasks: [CreateTaskInput!]!) {
    createTask(tasks: $tasks) {
      ...Task
    }
  }
  ${task}
`;

export interface CreateTaskVariables {
  tasks: CreateTaskInput[];
}

export interface CreateTaskResponse {
  createTask: Task[];
}

const updateAssignees = gql`
  mutation UpdateTaskAssignees($task: UpdateTaskInput!) {
    updateTaskAssignees(task: $task) {
      ...Task
    }
  }
  ${task}
`;

export interface UpdateTaskAssigneesVariables {
  task: UpdateTaskInput;
}

export interface UpdateTaskAssigneesResponse {
  updateTaskAssignees: Task;
}

const remove = gql`
  mutation DeleteTask($tasks: [DeleteTaskInput!]!) {
    deleteTask(tasks: $tasks) {
      ...Task
    }
  }
  ${task}
`;

export interface DeleteTaskVariables {
  tasks: DeleteTaskInput[];
}

export interface DeleteTaskResponse {
  deleteTask: Task[];
}

const orderByTask = gql`
  mutation OrderByTask($taskId: String!, $companyId: String!, $stockLocationId: String!) {
    orderByTask(taskId: $taskId, companyId: $companyId, stockLocationId: $stockLocationId) {
      ...Task
    }
  }
  ${task}
`;

export interface OrderByTaskVariables {
  taskId: string;
  companyId: string;
  stockLocationId: string;
}

export interface OrderByTaskResponse {
  orderByTask: Task;
}

export const TaskQueries = {
  get,
};

export const TaskMutations = {
  create,
  updateAssignees,
  remove,
  orderByTask,
};

export const TaskFragments = {
  task,
};
