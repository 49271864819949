import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { Product } from '../types/product';

const product = gql`
  fragment Product on Product {
    id
    version
    companyId
    productMasterDataId
    stockLocationId
    binId
    lotId
    containerId
    serial
    lpn
    unitQuantity
    quantity
  }
`;

const get = gql`
  query Products($companyId: String!, $page: Float, $stockLocationIds: [String!]) {
    products(companyId: $companyId, page: $page, stockLocationIds: $stockLocationIds) {
      data {
        ...Product
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${product}
`;

export interface GetProductVariables {
  companyId: string;
  page?: number;
  stockLocationIds?: string[];
}

export interface GetProductResponse {
  products: PaginatedProducts;
}

class PaginatedProducts extends forPaginated<Product>() {}

export const ProductQueries = {
  get,
};

export const ProductFragments = {
  product,
};
