import dayjs from 'dayjs';
import { EntityFilter } from '../filter.util';
import { Task } from '../../../../types/task';
import { StockLocation } from '../../../../types/stockLocation';
import { EntityType } from '../../../../types/comment';

export namespace TaskFilter {
  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: Task[],
    onFilter: (item: any, text: string) => boolean,
    stockLocations: Map<string, StockLocation>,
  ) {
    const result = items.filter(item => {
      if (filter.taskStatus && filter.taskStatus.size) {
        if (!filter.taskStatus.has(item.status)) return false;
        if (filter.taskStatus.has('Overdue') && !item.overdue) return false;
        if (!filter.taskStatus.has('Overdue') && item.overdue) return false;
      }

      if (filter.taskType && filter.taskType.size && !filter.taskType.has(item.type)) return false;

      if (filter.stockLocation && filter.stockLocation.size && !filter.stockLocation.has(item.stockLocationId)) {
        return false;
      }

      if (filter.country && filter.country.size) {
        const stockLocation = stockLocations.get(item.stockLocationId)?.address;
        if (!stockLocation) return false;

        if (stockLocation.countryCode && !filter.country.has(stockLocation.countryCode)) return false;
      }

      if (
        filter.bin &&
        filter.bin.size &&
        !item.identifiers
          .filter(i => i.type === EntityType.bin)
          .map(i => i.ids)
          .flat()
          .filter(i => filter.bin?.has(i)).length
      ) {
        return false;
      }

      if (
        filter.product &&
        filter.product.size &&
        !item.identifiers
          .filter(i => i.type === EntityType.pmd)
          .map(i => i.ids)
          .flat()
          .filter(i => filter.product?.has(i)).length
      ) {
        return false;
      }

      if (filter.user && filter.user.size && !item.assignedTo.filter(i => filter.user?.has(i)).length) {
        return false;
      }

      if (
        filter.timeFrame &&
        (dayjs(filter.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filter.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      return onFilter(item, textFilter);
    });

    return result.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
  }
}
